import {
    CREATE_DAT_COURSE_REQUEST,
    CREATE_DAT_COURSE_FAILURE,
    CREATE_DAT_COURSE_RESET,
    CREATE_DAT_COURSE_SUCCESS,
    FETCH_DAT_COURSES_FAILURE,
    FETCH_DAT_COURSES_REQUEST,
    FETCH_DAT_COURSES_SUCCESS,
    UPDATE_DAT_COURSE_FAILURE,
    UPDATE_DAT_COURSE_REQUEST,
    UPDATE_DAT_COURSE_RESET,
    UPDATE_DAT_COURSE_SUCCESS,
    DELETE_DAT_COURSE_FAILURE,
    DELETE_DAT_COURSE_REQUEST,
    DELETE_DAT_COURSE_RESET,
    DELETE_DAT_COURSE_SUCCESS,

    CREATE_DAT_COURSE_CATEGORY_REQUEST,
    CREATE_DAT_COURSE_CATEGORY_FAILURE,
    CREATE_DAT_COURSE_CATEGORY_RESET,
    CREATE_DAT_COURSE_CATEGORY_SUCCESS,

    CREATE_DAT_COURSE_LESSONS_REQUEST,
    CREATE_DAT_COURSE_LESSONS_FAILURE,
    CREATE_DAT_COURSE_LESSONS_RESET,
    CREATE_DAT_COURSE_LESSONS_SUCCESS,

    UPDATE_DAT_COURSE_CATEGORY_ORDER_REQUEST,
    UPDATE_DAT_COURSE_CATEGORY_ORDER_FAILURE,
    UPDATE_DAT_COURSE_CATEGORY_ORDER_RESET,
    UPDATE_DAT_COURSE_CATEGORY_ORDER_SUCCESS,

    UPDATE_DAT_COURSE_CATEGORY_REQUEST,
    UPDATE_DAT_COURSE_CATEGORY_FAILURE,
    UPDATE_DAT_COURSE_CATEGORY_RESET,
    UPDATE_DAT_COURSE_CATEGORY_SUCCESS,

    UPDATE_DAT_COURSE_LESSONS_REQUEST,
    UPDATE_DAT_COURSE_LESSONS_FAILURE,
    UPDATE_DAT_COURSE_LESSONS_RESET,
    UPDATE_DAT_COURSE_LESSONS_SUCCESS,

    DELETE_DAT_COURSE_LESSONS_REQUEST,
    DELETE_DAT_COURSE_LESSONS_FAILURE,
    DELETE_DAT_COURSE_LESSONS_RESET,
    DELETE_DAT_COURSE_LESSONS_SUCCESS,

    DELETE_DAT_COURSE_CATEGORY_REQUEST,
    DELETE_DAT_COURSE_CATEGORY_FAILURE,
    DELETE_DAT_COURSE_CATEGORY_RESET,
    DELETE_DAT_COURSE_CATEGORY_SUCCESS,

    UPDATE_DAT_MARKED_COMPLETE_LESSON_REQUEST,
    UPDATE_DAT_MARKED_COMPLETE_LESSON_FAILURE,
    UPDATE_DAT_MARKED_COMPLETE_LESSON_RESET,
    UPDATE_DAT_MARKED_COMPLETE_LESSON_SUCCESS,

    FETCH_DAT_MARKED_COMPLETE_LESSON_REQUEST,
    FETCH_DAT_MARKED_COMPLETE_LESSON_FAILURE,
    FETCH_DAT_MARKED_COMPLETE_LESSON_RESET,
    FETCH_DAT_MARKED_COMPLETE_LESSON_SUCCESS,

    CREATE_DAT_BUNDLECOURSE_REQUEST, 
    CREATE_DAT_BUNDLECOURSE_SUCCESS, 
    CREATE_DAT_BUNDLECOURSE_FAILURE,
    CREATE_DAT_BUNDLECOURSE_RESET,

    FETCH_DAT_BUNDLECOURSE_REQUEST, 
    FETCH_DAT_BUNDLECOURSE_SUCCESS, 
    FETCH_DAT_BUNDLECOURSE_FAILURE,
    FETCH_DAT_BUNDLECOURSE_RESET, 

} from "./ManageDatCoursesTypes"
import axios from "axios"

export const createDatCourseReset = () => {
    return {
        type: CREATE_DAT_COURSE_RESET,
    }
}

const createDatCourseRequest = () => {
    return {
        type: CREATE_DAT_COURSE_REQUEST,
    }
}

const createDatCourseSuccess = success => {
    return {
        type: CREATE_DAT_COURSE_SUCCESS,
        payload: success
    }
}

const createDatCourseFailure = error => {
    return {
        type: CREATE_DAT_COURSE_FAILURE,
        payload : error
    }
}

export const createDatCourse = (data) => {

    return (dispatch) => {

        dispatch(createDatCourseRequest());
        
        
        
        axios.post('/createDatCourse', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const DatCourse = response.data
            dispatch(createDatCourseSuccess(DatCourse))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createDatCourseFailure(errorMsg))
        })
        
    }

}


const fetchDatCoursesRequest = () => {
    return {
        type: FETCH_DAT_COURSES_REQUEST,
    }
}

const fetchDatCoursesSuccess = success => {
    return {
        type: FETCH_DAT_COURSES_SUCCESS,
        payload: success
    }
}

const fetchDatCoursesFailure = error => {
    return {
        type: FETCH_DAT_COURSES_FAILURE,
        payload : error
    }
}

export const fetchDatCourses = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchDatCoursesRequest());
        
        
        axios.post('/getDatCourses' , data)
        .then(response => {
            const DatCourses = response.data
            dispatch(fetchDatCoursesSuccess(DatCourses))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchDatCoursesFailure(errorMsg))
        })
        
    }

}


export const updateDatCourseReset = () => {
    return {
        type: UPDATE_DAT_COURSE_RESET,
    }
}

const updateDatCourseRequest = () => {
    return {
        type: UPDATE_DAT_COURSE_REQUEST,
    }
}

const updateDatCourseSuccess = success => {
    return {
        type: UPDATE_DAT_COURSE_SUCCESS,
        payload: success
    }
}

const updateDatCourseFailure = error => {
    return {
        type: UPDATE_DAT_COURSE_FAILURE,
        payload : error
    }
}

export const updateDatCourse = (data) => {

    return (dispatch) => {

        dispatch(updateDatCourseRequest());
        
        
        
        axios.post('/updateDatCourse', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const DatCourse = response.data
            dispatch(updateDatCourseSuccess(DatCourse))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateDatCourseFailure(errorMsg))
        })
        
    }

}

export const deleteDatCourseReset = () => {
    return {
        type: DELETE_DAT_COURSE_RESET,
    }
}

const deleteDatCourseRequest = () => {
    return {
        type: DELETE_DAT_COURSE_REQUEST,
    }
}

const deleteDatCourseSuccess = success => {
    return {
        type: DELETE_DAT_COURSE_SUCCESS,
        payload: success
    }
}

const deleteDatCourseFailure = error => {
    return {
        type: DELETE_DAT_COURSE_FAILURE,
        payload : error
    }
}

export const deleteDatCourse = (data) => {

    return (dispatch) => {

        dispatch(deleteDatCourseRequest());
        
        
        
        axios.post('/deleteDatCourse', data)
        .then(response => {
            const DatCourse = response.data
            dispatch(deleteDatCourseSuccess(DatCourse))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteDatCourseFailure(errorMsg))
        })
        
    }

}

export const createDatCourseCategoryReset = () => {
    return {
        type: CREATE_DAT_COURSE_CATEGORY_RESET,
    }
}

const createDatCourseCategoryRequest = () => {
    return {
        type: CREATE_DAT_COURSE_CATEGORY_REQUEST,
    }
}

const createDatCourseCategorySuccess = success => {
    return {
        type: CREATE_DAT_COURSE_CATEGORY_SUCCESS,
        payload: success
    }
}

const createDatCourseCategoryFailure = error => {
    return {
        type: CREATE_DAT_COURSE_CATEGORY_FAILURE,
        payload : error
    }
}

export const createDatCourseCategory = (data) => {

    return (dispatch) => {

        dispatch(createDatCourseCategoryRequest());
        
        
        
        axios.post('/createDatCourseCategory', data)
        .then(response => {
            const DatCourseCategory = response.data
            dispatch(createDatCourseCategorySuccess(DatCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createDatCourseCategoryFailure(errorMsg))
        })
        
    }

}

export const createDatCourseLessonsReset = () => {
    return {
        type: CREATE_DAT_COURSE_LESSONS_RESET,
    }
}

const createDatCourseLessonsRequest = () => {
    return {
        type: CREATE_DAT_COURSE_LESSONS_REQUEST,
    }
}

const createDatCourseLessonsSuccess = success => {
    return {
        type: CREATE_DAT_COURSE_LESSONS_SUCCESS,
        payload: success
    }
}

const createDatCourseLessonsFailure = error => {
    return {
        type: CREATE_DAT_COURSE_LESSONS_FAILURE,
        payload : error
    }
}

export const createDatCourseLessons = (data) => {

    return (dispatch) => {

        dispatch(createDatCourseLessonsRequest());
        
        
        
        axios.post('/createDatCourseLessons', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const DatCourseLessons = response.data
            dispatch(createDatCourseLessonsSuccess(DatCourseLessons))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createDatCourseLessonsFailure(errorMsg))
        })
        
    }

}


export const updateDatCourseCategoryOrderReset = () => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_ORDER_RESET,
    }
}

const updateDatCourseCategoryOrderRequest = () => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_ORDER_REQUEST,
    }
}

const updateDatCourseCategoryOrderSuccess = success => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_ORDER_SUCCESS,
        payload: success
    }
}

const updateDatCourseCategoryOrderFailure = error => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_ORDER_FAILURE,
        payload : error
    }
}

export const updateDatCourseCategoryOrder = (data) => {

    return (dispatch) => {

        dispatch(updateDatCourseCategoryOrderRequest());
        
        
        
        axios.post('/updateDatCourseCategoryOrder', data)
        .then(response => {
            const DatCourseCategory = response.data
            dispatch(updateDatCourseCategoryOrderSuccess(DatCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateDatCourseCategoryOrderFailure(errorMsg))
        })
        
    }

}


export const updateDatCourseCategoryReset = () => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_RESET,
    }
}

const updateDatCourseCategoryRequest = () => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_REQUEST,
    }
}

const updateDatCourseCategorySuccess = success => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_SUCCESS,
        payload: success
    }
}

const updateDatCourseCategoryFailure = error => {
    return {
        type: UPDATE_DAT_COURSE_CATEGORY_FAILURE,
        payload : error
    }
}

export const updateDatCourseCategory = (data) => {

    return (dispatch) => {

        dispatch(updateDatCourseCategoryRequest());
        
        
        
        axios.post('/updateDatCourseCategory', data)
        .then(response => {
            const DatCourseCategory = response.data
            dispatch(updateDatCourseCategorySuccess(DatCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateDatCourseCategoryFailure(errorMsg))
        })
        
    }

}

export const updateDatCourseLessonsReset = () => {
    return {
        type: UPDATE_DAT_COURSE_LESSONS_RESET,
    }
}

const updateDatCourseLessonsRequest = () => {
    return {
        type: UPDATE_DAT_COURSE_LESSONS_REQUEST,
    }
}

const updateDatCourseLessonsSuccess = success => {
    return {
        type: UPDATE_DAT_COURSE_LESSONS_SUCCESS,
        payload: success
    }
}

const updateDatCourseLessonsFailure = error => {
    return {
        type: UPDATE_DAT_COURSE_LESSONS_FAILURE,
        payload : error
    }
}

export const updateDatCourseLessons = (data) => {

    return (dispatch) => {

        dispatch(updateDatCourseLessonsRequest());
        
        
        
        axios.post('/updateDatCourseLessons', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const DatCourseCategory = response.data
            dispatch(updateDatCourseLessonsSuccess(DatCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateDatCourseLessonsFailure(errorMsg))
        })
        
    }

}

export const deleteDatCourseLessonsReset = () => {
    return {
        type: DELETE_DAT_COURSE_LESSONS_RESET,
    }
}

const deleteDatCourseLessonsRequest = () => {
    return {
        type: DELETE_DAT_COURSE_LESSONS_REQUEST,
    }
}

const deleteDatCourseLessonsSuccess = success => {
    return {
        type: DELETE_DAT_COURSE_LESSONS_SUCCESS,
        payload: success
    }
}

const deleteDatCourseLessonsFailure = error => {
    return {
        type: DELETE_DAT_COURSE_LESSONS_FAILURE,
        payload : error
    }
}

export const deleteDatCourseLessons = (data) => {

    return (dispatch) => {

        dispatch(deleteDatCourseLessonsRequest());
        
        
        
        axios.post('/deleteDatCourseLessons', data)
        .then(response => {
            const DatCourseLessons = response.data
            dispatch(deleteDatCourseLessonsSuccess(DatCourseLessons))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteDatCourseLessonsFailure(errorMsg))
        })
        
    }

}

export const deleteDatCourseCategoryReset = () => {
    return {
        type: DELETE_DAT_COURSE_CATEGORY_RESET,
    }
}

const deleteDatCourseCategoryRequest = () => {
    return {
        type: DELETE_DAT_COURSE_CATEGORY_REQUEST,
    }
}

const deleteDatCourseCategorySuccess = success => {
    return {
        type: DELETE_DAT_COURSE_CATEGORY_SUCCESS,
        payload: success
    }
}

const deleteDatCourseCategoryFailure = error => {
    return {
        type: DELETE_DAT_COURSE_CATEGORY_FAILURE,
        payload : error
    }
}

export const deleteDatCourseCategory = (data) => {

    return (dispatch) => {

        dispatch(deleteDatCourseCategoryRequest());
        
        
        
        axios.post('/deleteDatCourseCategory', data)
        .then(response => {
            const DatCourseCategory = response.data
            dispatch(deleteDatCourseCategorySuccess(DatCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteDatCourseCategoryFailure(errorMsg))
        })
        
    }

}

export const updateDatMarkedCompleteLessonReset = () => {
    return {
        type: UPDATE_DAT_MARKED_COMPLETE_LESSON_RESET,
    }
}

const updateDatMarkedCompleteLessonRequest = () => {
    return {
        type: UPDATE_DAT_MARKED_COMPLETE_LESSON_REQUEST,
    }
}

const updateDatMarkedCompleteLessonSuccess = success => {
    return {
        type: UPDATE_DAT_MARKED_COMPLETE_LESSON_SUCCESS,
        payload: success
    }
}

const updateDatMarkedCompleteLessonFailure = error => {
    return {
        type: UPDATE_DAT_MARKED_COMPLETE_LESSON_FAILURE,
        payload : error
    }
}

export const updateDatMarkedCompleteLesson = (data) => {

    return (dispatch) => {

        dispatch(updateDatMarkedCompleteLessonRequest());
        
        
        
        axios.post('/updateDatMarkedCompleteLesson', data)
        .then(response => {
            const MarkedCompleteLesson = response.data
            dispatch(updateDatMarkedCompleteLessonSuccess(MarkedCompleteLesson))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateDatMarkedCompleteLessonFailure(errorMsg))
        })
        
    }

}

export const fetchDatMarkedCompleteLessonReset = () => {
    return {
        type: FETCH_DAT_MARKED_COMPLETE_LESSON_RESET,
    }
}

const fetchDatMarkedCompleteLessonRequest = () => {
    return {
        type: FETCH_DAT_MARKED_COMPLETE_LESSON_REQUEST,
    }
}

const fetchDatMarkedCompleteLessonSuccess = success => {
    return {
        type: FETCH_DAT_MARKED_COMPLETE_LESSON_SUCCESS,
        payload: success
    }
}

const fetchDatMarkedCompleteLessonFailure = error => {
    return {
        type: FETCH_DAT_MARKED_COMPLETE_LESSON_FAILURE,
        payload : error
    }
}

export const fetchDatMarkedCompleteLesson = (data) => {

    return (dispatch) => {

        dispatch(fetchDatMarkedCompleteLessonRequest());
        
        
        
        axios.post('/fetchDatMarkedCompleteLesson', data)
        .then(response => {
            const MarkedCompleteLesson = response.data
            dispatch(fetchDatMarkedCompleteLessonSuccess(MarkedCompleteLesson))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchDatMarkedCompleteLessonFailure(errorMsg))
        })
        
    }

}

export const createDatBundleCoursesReset = () => {
    return {
        type: CREATE_DAT_BUNDLECOURSE_RESET,
    }
}

const createDatBundleCoursesRequest = () => {
    return {
        type: CREATE_DAT_BUNDLECOURSE_REQUEST,
    }
}

const createDatBundleCoursesSuccess = success => {
    return {
        type: CREATE_DAT_BUNDLECOURSE_SUCCESS,
        payload: success
    }
}

const createDatBundleCoursesFailure = error => {
    return {
        type: CREATE_DAT_BUNDLECOURSE_FAILURE,
        payload : error
    }
}

export const createDatBundleCourses = (data) => {

    return (dispatch) => {

        dispatch(createDatBundleCoursesRequest());

        axios.post('/createDatBundleCourses', data)
        .then(response => {
            const bundles = response.data
            dispatch(createDatBundleCoursesSuccess(bundles))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createDatBundleCoursesFailure(errorMsg))
        })
        
    }

}

export const fetchDatBundleCoursesReset = () => {
    return {
        type: FETCH_DAT_BUNDLECOURSE_RESET,
    }
}

const fetchDatBundleCoursesRequest = () => {
    return {
        type: FETCH_DAT_BUNDLECOURSE_REQUEST,
    }
}

const fetchDatBundleCoursesSuccess = success => {
    return {
        type: FETCH_DAT_BUNDLECOURSE_SUCCESS,
        payload: success
    }
}

const fetchDatBundleCoursesFailure = error => {
    return {
        type: FETCH_DAT_BUNDLECOURSE_FAILURE,
        payload : error
    }
}

export const fetchDatBundleCourses = (data) => {

    return (dispatch) => {

        dispatch(fetchDatBundleCoursesRequest());
        
        axios.post('/fetchDatBundleCourses', data)
        .then(response => {
            const bundleCourses = response.data
            dispatch(fetchDatBundleCoursesSuccess(bundleCourses))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchDatBundleCoursesFailure(errorMsg))
        })
        
    }

}

