import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter,
    Badge
  } from "reactstrap";

import {createDatCourseCategory, createDatCourseCategoryReset, updateDatCourseCategory, updateDatCourseCategoryReset} from 'redux/ManageDatCourses/ManageDatCoursesActions'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'

const AddDatCourseCategoryModal = (props) => {

    let modalAddDatCourseCategory = props.modalAddDatCourseCategoryModal;
    let toggleAddDatCourseCategory = props.toggleAddDatCourseCategoryModal;


    const [categoryLists , setCategoryLists] = useState([])

    const dispatch = useDispatch();
    

    // console.log(props.items)

    useEffect(() => {
        if(props.editCourseCategory){
            setCategoryName(props.categoryToUpdate.text)
        }
    }, [modalAddDatCourseCategory])
    
    
    useEffect(() => {

        if(typeof props.items != "undefined") {
            
            setCategoryLists(props.items)
        }
    }, [props.items])
    

    const [categoryName , setCategoryName] = useState([])

    const handleCreateCategory = (e) => {
        e.preventDefault()

        Swal.showLoading()

        const updatedCategoryLists = [...categoryLists, {id : categoryLists.length + 1, text: categoryName, lessons: []}];
        console.log(updatedCategoryLists)

        setCategoryLists(updatedCategoryLists)

        dispatch(createDatCourseCategory({datCourseId : props.courseData.datCourseId ,updatedCategoryLists : updatedCategoryLists}))

    }

    useEffect(() => {
    
  
        if(props.createDatCourseCategory.datcourse.length && typeof props.createDatCourseCategory.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully added DAT course category!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(createDatCourseCategoryReset());
            toggleAddDatCourseCategory()
          })
          
        }
      
        else if(typeof props.createDatCourseCategory.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to add DAT course category!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(createDatCourseCategoryReset());
            toggleAddDatCourseCategory()
          })    
                
        }
      
    }, [props.createDatCourseCategory])

    useEffect(() => {
    
  
        if(props.updateDatCourseCategory.datcourse.length && typeof props.updateDatCourseCategory.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully updated DAT course category!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateDatCourseCategoryReset());
            toggleAddDatCourseCategory()
          })
          
        }
      
        else if(typeof props.updateDatCourseCategory.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to update DAT course category!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateDatCourseCategoryReset());
            toggleAddDatCourseCategory()
          })    
                
        }
      
    }, [props.updateDatCourseCategory])

    const handleUpdateCategory = (e) =>{

        e.preventDefault()


        // console.log(categoryLists.find(value => value.id == props.categoryToUpdate.id))
        Swal.showLoading()
        const categoryFindById = categoryLists.find(value => value.id == props.categoryToUpdate.id)

        
        categoryFindById.text = categoryName

        

        const updatedCategoryLists = categoryLists;

        setCategoryLists(updatedCategoryLists)
        // console.log(updatedCategoryLists)
        dispatch(updateDatCourseCategory({datCourseId : props.courseData.datCourseId ,updatedCategoryLists : updatedCategoryLists}))

    }
  return (
    <div>
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddDatCourseCategory}  toggle={toggleAddDatCourseCategory}>
                    
            <ModalBody className='modal-body p-0'>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">{props.editCourseCategory ? "Edit" : "Add"} Category</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            
                        </Col>
                        </Row>
                    </CardHeader>
                    <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                        <h6 className="heading-small text-muted mb-4">
                            Category Information
                        </h6>
                        <div className="pl-lg-4">
                            <Row>

                                <Col lg="12">
                                    <FormGroup>

                                        <label
                                            className="form-control-label"
                                            
                                        >
                                            Name <span className='text-danger'>*</span>
                                        </label>
                                        <Input
                                            className="form-control-alternative"

                                            defaultValue={props.editCourseCategory ? categoryName : ""}
                                            
                                            onChange={e => setCategoryName(e.target.value)}
                                            type="text"
                                        />

                                    </FormGroup>
                                </Col>

                            </Row>
                        </div>

                 
                        
                        
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary"  onClick={toggleAddDatCourseCategory}>
                    Cancel
                </Button>

                {props.editCourseCategory === false ? 
                    <Button color="primary" onClick={handleCreateCategory}>
                        Save
                    </Button>
                    :
                    <Button color="primary" onClick={handleUpdateCategory}>
                        Update
                    </Button>
                }
            </ModalFooter>
            
        </Modal>
    </div>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createDatCourseCategory : state.createDatCourseCategory,
        updateDatCourseCategory : state.updateDatCourseCategory
    }
      
}

export default connect(mapStateToProps)(AddDatCourseCategoryModal)
