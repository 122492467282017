/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index";
import Tables from "views/examples/Tables";
import PersonalStatement from "views/pages/PersonalStatement";
import WorkActivities from "views/pages/WorkActivities";
import Courses from "views/pages/Courses";
import Mcat from "views/pages/Mcat";
import  SchoolSearch  from "views/pages/SchoolSearch";
import MyApplication from "views/pages/MyApplication";
import Profile from "views/pages/Profile";
import ClientDashboard from "views/pages/Dashboard/ClientDashboard";
import McatPowerbook from "views/pages/McatPowerbook";
import Ideas from "views/pages/Ideas";
import Chatbot from "views/pages/Chatbot";
import Plans from "views/pages/Plans";
import Payment from "views/pages/Payment";
import PlansPage from "views/pages/PlansPage";
import Community from "views/pages/Community"
import CommunityProMembers from "views/pages/CommunityProMembers"
import Support from "views/pages/Support";
import ThankYou from "views/pages/ThankYou";
import ScheduleTutor from "views/pages/ScheduleTutor";
import UpgradeUas from "views/pages/UpgradeUas";
import Achievements from "views/pages/Achievements";
import TpcCourses from "views/pages/TpcCourses";
import ManageCoachTutorAvailability from "views/ManagePages/ManageCoachTutorAvailability";
import ManageTutorRequests from "views/ManagePages/ManageTutorRequests";
import Covid19Impact from "views/pages/Covid19Impact"
import ManualDexterity from "views/pages/ManualDexterity"
import Topic from 'views/pages/Topic'
import Biology from "views/DAT/Biology"
import GeneralChemistry from "views/DAT/GeneralChemistry"
import OrganicChemistry from "views/DAT/OrganicChemistry"

import ManageUsers from "views/ManagePages/ManageUsers";
var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: ClientDashboard,
    layout: "/client"
  },
    {
    path: "/manage-users",
    name: "Manage Users",
    icon: "fas fa-user text-info",
    component: ManageUsers,
    layout: "/client"
  },
    {
    path: "/manage-coach-tutor-availability",
    name: "Manage Coach Tutor Availability",
    icon: "fas fa-calendar-alt text-pink",
    component: ManageCoachTutorAvailability,
    layout: "/client"
  },

  {
    path: "/manage-tutor-requests",
    name: "Manage Tutor Requests",
    icon: "fas fa-calendar-alt text-yellow",
    component: ManageTutorRequests,
    layout: "/client"
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "ni ni-books text-orange",
    component: Courses,
    layout: "/client"
  },
  {
    path: "/work-activities",
    name: "Experiences",
    icon: "ni ni-planet text-blue",
    component: WorkActivities,
    layout: "/client"
  },
  {
    path: "/achievements",
    name: "Achievements",
    icon: "ni ni-circle-08 text-pink",
    component: Achievements,
    layout: "/client"
  },
  {
    path: "/dat-biology",
    name: "DAT",
    icon: "ni ni-books text-danger",
    component: Biology,
    layout: "/client",
    items:[
      {
        path: "/dat-biology",
        name: "Biology",
        icon: "ni ni-books text-green",
        component: Biology,
        layout: "/client"
      },
      {
        path: "/dat-general-chemistry",
        name: "General Chemistry",
         icon: "ni ni-books text-green",
        component: GeneralChemistry,
        layout: "/client"
      },
      {
        path: "/dat-organic-chemistry",
        name: "Organic Chemistry",
         icon: "ni ni-books text-green",
        component: OrganicChemistry,
        layout: "/client"
      },
      // {
      //   path: "/dat-perceptual-ability",
      //   name: "Perceptual Ability",
      //    icon: "ni ni-books text-green",
      //   component: Topic,
      //   layout: "/client"
      // },
      // {
      //   path: "/dat-reading-comprehension",
      //   name: "Reading Comprehension",
      //    icon: "ni ni-books text-green",
      //   component: Topic,
      //   layout: "/client"
      // },
      // {
      //   path: "/dat-quantitative-reasoning",
      //   name: "Quantitative Reasoning",
      //    icon: "ni ni-books text-green",
      //   component: Topic,
      //   layout: "/client"
      // },
  ]
},

{
  path: "/dat-biology",
  name: "Biology",
  icon: "ni ni-books text-green",
  component: Biology,
  layout: "/client"
},
{
  path: "/dat-general-chemistry",
  name: "General Chemistry",
   icon: "ni ni-books text-green",
  component: GeneralChemistry,
  layout: "/client"
},
{
  path: "/dat-organic-chemistry",
  name: "Organic Chemistry",
   icon: "ni ni-books text-green",
  component: OrganicChemistry,
  layout: "/client"
},
{
  path: "/dat-perceptual-ability",
  name: "Perceptual Ability",
   icon: "ni ni-books text-green",
  component: Topic,
  layout: "/client"
},
{
  path: "/dat-reading-comprehension",
  name: "Reading Comprehension",
   icon: "ni ni-books text-green",
  component: Topic,
  layout: "/client"
},
{
  path: "/dat-quantitative-reasoning",
  name: "Quantitative Reasoning",
   icon: "ni ni-books text-green",
  component: Topic,
  layout: "/client"
},
  {
    path: "/personal-statement",
    name: "Personal Statement",
    icon: "ni ni-book-bookmark text-teal",
    component: PersonalStatement,
    layout: "/client"
  },
  {
    path: "/covid-19-impact",
    name: "COVID-19 Impact",
    icon: "ni ni-book-bookmark text-teal",
    component: Covid19Impact,
    layout: "/client"
  },
  {
    path: "/manual-dexterity",
    name: "Manual Dexterity",
    icon: "ni ni-book-bookmark text-teal",
    component: ManualDexterity,
    layout: "/client"
  },
  
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/client"
  // },
//   {
//     path: "/user-profile",
//     name: "User Profile",
//     icon: "ni ni-single-02 text-yellow",
//     component: Profile,
//     layout: "/client"
//   },
  {
    path: "/school-search",
    name: "School Search",
    icon: "ni ni-building text-green ",
    component: SchoolSearch,
    layout: "/client"
  },
  
  {
    path: "/my-application",
    name: "My Application",
    icon: "ni ni-bullet-list-67 text-info",
    component: MyApplication,
    layout: "/client"
  },
  
//   {
//     path: "/login",
//     name: "Login",
//     icon: "ni ni-key-25 text-info",
//     component: Login,
//     layout: "/auth"
//   },
  // {
  //   path: "/mcat-powerbook",
  //   name: "MCAT Powerbook",
  //   icon: "ni ni-book-bookmark text-red",
  //   component: McatPowerbook,
  //   layout: "/client"
  // },
  // {
  //   path: "/ideas",
  //   name: "Ideas",
  //   icon: "ni ni-bulb-61 text-yellow",
  //   component: Ideas,
  //   layout: "/client"
  // },
  // {
  //   path: "/chatbot",
  //   name: "Chatbot",
  //   icon: "fas fa-comment text-info",
  //   component: Chatbot,
  //   layout: "/client"
  // },
 
  
  {
    path: "/plans",
    name: "Plans",
    icon: "ni ni-circle-08 text-pink",
    component: PlansPage,
    layout: "/client"
  },
    {
    path: "/community",
    name: "Community",
    icon: "fas fa-users text-warning",
    component: Community,
    layout: "/client",
    items:[
      {
        path: "/community-free-members",
        name: "Open Community",
        icon: "fas fa-users text-warning",
        component: Community,
        layout: "/client"
      },
       {
      path: "/community-pro-members",
      name: "Pro Members",
      icon: "fas fa-user-tie text-green",
      component: CommunityProMembers,
      layout: "/client"
    },
       {
      path: "/community-uas",
      name: "Ultimate Advising Students",
      icon: "fas fa-user-shield text-info",
      component: Community,
      layout: "/client"
    },
    ]
  },
  {
      path: "/community-free-members",
      name: "Open Community",
      icon: "fas fa-users text-warning",
      component: Community,
      layout: "/client"
    },
  {
    path: "/community-pro-members",
    name: "Pro Members",
    icon: "fas fa-user-tie text-green",
    component: CommunityProMembers,
    layout: "/client"
  },
{
  path: "/community-uas",
  name: "Ultimate Advising Students",
  icon: "fas fa-user-shield text-info",
  component: UpgradeUas,
  layout: "/client"
},
  {
    path: "/report-bugs",
    name: "Report Bugs",
    icon: "fas fa-bug text-info",
    component: Support,
    layout: "/client"
  },
  {
    path: "/my-profile",
    name: "My Profile",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/client"
  },
  {
    path: "/congrats-monthly",
    name: "Thank You Monthly",
    icon: "ni ni-circle-08 text-pink",
    component: ThankYou,
    layout: "/client"
  },
  {
    path: "/congrats-yearly",
    name: "Thank You Yearly",
    icon: "ni ni-circle-08 text-pink",
    component: ThankYou,
    layout: "/client"
  },
  {
    path: "/congrats-lifetime",
    name: "Thank You Lifetime",
    icon: "ni ni-circle-08 text-pink",
    component: ThankYou,
    layout: "/client"
  },
  {
    path: "/congrats-lifetime",
    name: "Thank You Lifetime",
    icon: "ni ni-circle-08 text-pink",
    component: ThankYou,
    layout: "/client"
  },
  {
    path: "/schedule-tutor",
    name: "Schedule Tutor",
    icon: "fas fa-calendar-alt text-pink",
    component: ScheduleTutor,
    layout: "/client"
  },
  // {
  //   path: "/upgrade-uas",
  //   name: "Upgrade Uas",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: UpgradeUas,
  //   layout: "/client"
  // },
  {
    path: "/tpc-courses",
    name: "TPC Courses",
    icon: "ni ni-books text-primary",
    component: TpcCourses,
    layout: "/client"
  },
  
];
export default routes;
