import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    CardImg,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Header from 'components/Headers/Header';

import {arrayMoveImmutable} from 'array-move';

import AddDatCourseCategoryModal from 'components/Modal/AddDatCourseCategoryModal';
import AddDatCourseCategoryLessonsModal from 'components/Modal/AddDatCourseCategoryLessonsModal';

import { connect, useDispatch } from 'react-redux';

import { updateDatCourseCategoryOrder, deleteDatCourseLessons, deleteDatCourseCategory, deleteDatCourseLessonsReset, deleteDatCourseCategoryReset, fetchDatCourses } from 'redux/ManageDatCourses/ManageDatCoursesActions';
import Swal from 'sweetalert2'

const ManageDatCoursesLessons = (props) => {

    const [modalAddDatCourseCategoryModal, setModalAddDatCourseCategoryModal] = useState(false);
    const [modalAddDatCourseCategoryLessonsModal, setModalAddDatCourseCategoryLessonsModal] = useState(false);

    const [editCourseCategory, setEditCourseCategory] = useState(false);
    const [editCourseCategoryLessons, setEditCourseCategoryLessons] = useState(false);
    
    const [categoryId, setCategoryId] = useState("");

    const dispatch = useDispatch()

    const [courseProps, setCourseProps] = useState(props.courseData)

    const toggleAddDatCourseCategoryModal = () =>{
        
        setModalAddDatCourseCategoryModal(!modalAddDatCourseCategoryModal);
        
    } 

    

    const toggleAddDatCourseCategoryLessonsModal = () =>{
        
        setModalAddDatCourseCategoryLessonsModal(!modalAddDatCourseCategoryLessonsModal);
        

        
        // let set = fetchDatCourses.find(category => category.id == fields.oldCategory);
    } 

    useEffect(() => {
        
        if(props.createDatCourseCategory.datcourse.length){
            setItems(props.createDatCourseCategory.datcourse[0].category)
        }
        
        

    }, [props.createDatCourseCategory])

    
    

    useEffect(() => {
        if(props.fetchDatCourses.datcourses.length){
            
            if(typeof props.courseData.category != "undefined"){
                setItems(props.fetchDatCourses.datcourses.find(course => course.datCourseId == props.courseData.datCourseId).category)
                
            }
            else{
                setItems([])
            }
            
        }
    },[props.fetchDatCourses])
    
    const [items, setItems]= useState(props.fetchDatCourses.datcourses.length && typeof props.courseData.category !== "undefined" ? props.fetchDatCourses.datcourses.find(course => course.datCourseId == props.courseData.datCourseId).category : []);

    const DragHandle = SortableHandle(() => <span style={{ cursor: 'grab' }} className='text-info'><i className="fas fa-bars"></i>&nbsp;</span>);
    

    const [selectedCategory, setSelectedCategory] = useState(false);
    
    const handleCreateLesson = (e,value, categoryId) => {
        e.preventDefault()
        setCategoryId(categoryId)
        setEditCourseCategoryLessons(false)
        setEditCourseCategory(false)
        setSelectedCategory(value)
        setModalAddDatCourseCategoryLessonsModal(!modalAddDatCourseCategoryLessonsModal);

    }


    const [categoryToUpdate, setCategoryToUpdate] = useState({})
    const [lessonToUpdate, setLessonToUpdate] = useState({})

    

    const handleUpdateCategory = (e,value) => {
        e.preventDefault()
        toggleAddDatCourseCategoryModal()

        setEditCourseCategory(true)

        setCategoryToUpdate(value)
    }


    const handleUpdateLesson = (e, value, categoryId) => {
        e.preventDefault()
        
        value.categoryId = categoryId
        setCategoryId(categoryId)

        
        setEditCourseCategoryLessons(true)
        // setModalAddDatCourseCategoryLessonsModal(!modalAddDatCourseCategoryLessonsModal);
        setLessonToUpdate(value)
        setModalAddDatCourseCategoryLessonsModal(!modalAddDatCourseCategoryLessonsModal);

    }
    

    const SortableItem = SortableElement(({ value , categoryId}) => (
        
        <Row className='mb-3'>
            <Col>
                <Card className="shadow">
                    <CardHeader className="border-0">
                        
                            
                            {/* {value.text} */}

                            <div className="row mb-3">
                                <div className='col-lg-7'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        Category {value.id}
                                    </h6>
                                    <h2 className="mb-0"><DragHandle /> {value.text}</h2>
                                </div>
                                <div className='col-lg-5 text-lg-right mt-lg-0 mt-3 mb-lg-0 mb-3 text-center'>
                                    <Button color="info" onClick={e => handleCreateLesson(e, value , categoryId)}><i className="fas fa-plus-circle"></i></Button>
                                    <Button color="warning" onClick={e => handleUpdateCategory(e, value)}><i className="fas fa-pen"></i></Button>
                                    <Button color="danger" onClick={e => handleDeleteDatCourseCategory(e, categoryId )}><i className="fas fa-trash"></i></Button>
                                </div>
                            </div>
                            
                            {value.lessons && (
                                <SortableLessonList
                                categoryId ={value.id}
                                items={value.lessons}
                                onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, true, value.id)}
                                isChild
                                />
                            )}
                            
                        
                    </CardHeader>
                    {/* <CardBody>
                        
                        
                    </CardBody> */}
                </Card>
                
            </Col> 
        </Row>

    ));

    const SortableLessonItem = SortableElement(({ value, categoryId, items }) => (
        
        <Row className='mb-2'>
            <Col>
                <Card className="shadow">
                    <CardHeader className="border-0">

                            {/* {value.text} */}

                            <div class="row">
                                <div className='col-lg-8'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        Lesson {value.id} - {value.visibility == "draft" ? <label className='text-warning'>{value.visibility}</label> : <label className='text-success'>{value.visibility}</label>}
                                    </h6>
                                    <h2 className="mb-0"><DragHandle /> {value.title}</h2>
                                </div>
                                <div className='col-lg-4 text-lg-right mt-lg-0 mt-3 text-center'>
                                    {/* <Button color="info" onClick={e => handleCreateLesson(value)}><i className="fas fa-plus-circle"></i></Button> */}
                                    <Button color="primary" onClick={e => handleUpdateLesson(e,value, categoryId)}><i className="fas fa-pen"></i></Button>
                                    <Button color="danger" onClick={e => handleDeleteDatCourseLesson(e,value, items, categoryId)}><i className="fas fa-trash"></i></Button>
                                </div>
                            </div>   
                        
                    </CardHeader>
                    {/* <CardBody>
                        
                        
                    </CardBody> */}
                </Card>
                
            </Col> 
        </Row>

    ));
    
    
    const SortableList = SortableContainer(({ items, isChild }) => {
        
        const onSortEndHandler = ({ oldIndex, newIndex }) => {
            onSortEnd(oldIndex, newIndex, isChild);
            
        };
    
        return (
        <ul className="list-unstyled">
            {items.map((value, index) => (
            <SortableItem key={`item-${value.id}`} index={index} categoryId={value.id} value={value} onSortEnd={onSortEndHandler} />
            ))}
        </ul>
        );
    });
    
    const DraggableSortList = () => {
    
        return <SortableList items={items} onSortEnd={onSortEnd} />;
    };

    const SortableLessonList = SortableContainer(({ items, isChild, categoryId }) => {
        
        const onSortEndHandler = ({ oldIndex, newIndex }) => {
            onSortEnd(oldIndex, newIndex, isChild);
        };
    
        return (
        <ul className="list-unstyled">
            {items.map((value, index) => (
            <SortableLessonItem key={`item-${value.id}`} categoryId={categoryId} index={index} value={value} onSortEnd={onSortEndHandler} items={items} />
            ))}
        </ul>
        );
    });
    
    const DraggableSortLessonList = () => {
    
        return <SortableLessonList items={items} onSortEnd={onSortEnd} />;
    };

    // const [items, setItems] = useState(initialItems);

    const onSortEnd = (oldIndex, newIndex, isChild, parentId) => {
        
        if (isChild) {
            const updatedItems = [...items];
            const parentIndex = updatedItems.findIndex(item => item.id == parentId);
            
            const parentItem = updatedItems[parentIndex];
            
            parentItem.lessons = arrayMoveImmutable(parentItem.lessons, oldIndex.oldIndex, oldIndex.newIndex);
            
            parentItem.lessons.forEach((lesson, index) => {
                lesson.id = index + 1;
            });
            
            setItems(updatedItems);

            dispatch(updateDatCourseCategoryOrder({category : updatedItems, datCourseId : props.courseData.datCourseId, handle : props.userDetails.handle}))

            
        } else {

            let newOrderCategories = arrayMoveImmutable(items, oldIndex.oldIndex, oldIndex.newIndex)
            newOrderCategories.forEach((category, index) => {
                category.id = index + 1;
            });

            
            setItems(newOrderCategories);

            dispatch(updateDatCourseCategoryOrder({category : newOrderCategories, datCourseId : props.courseData.datCourseId, handle : props.userDetails.handle}))
        }
    };

    
    const handleDeleteDatCourseLesson = (e, value, lessonsList, categoryId) => {
        e.preventDefault()

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            
            if (result.isConfirmed) {
                Swal.showLoading()
                let lessons = lessonsList;
        
                lessons = lessons.filter(lesson => lesson.id != value.id)
                lessons.forEach((lesson, index) => {
                    lesson.id = index + 1;
                });

                let categories = items

                let categorySelected = categories.find(category => category.id == categoryId)
                categorySelected.lessons = lessons
                
                
                
                dispatch(deleteDatCourseLessons({handle: props.userDetails.handle, categories : categories, datCourseId: props.courseData.datCourseId}))
        
            }
        })
    }

    const handleDeleteDatCourseCategory = (e, categoryId) => {
        e.preventDefault()
        
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            
            if (result.isConfirmed) {
                
                let categories = items
                let categorySelected = categories.filter(category => category.id != categoryId)
                categorySelected.forEach((category, index) => {
                    category.id = index + 1;
                });
                        
                
                dispatch(deleteDatCourseCategory({handle: props.userDetails.handle, categories : categorySelected, datCourseId: props.courseData.datCourseId}))
        
            }
        })
    }

    useEffect(() => {
    
  
        if(props.deleteDatCourseLessons.datcourse.length && typeof props.deleteDatCourseLessons.error === "undefined"){
          
            Swal.fire({
                title: 'Success!',
                text: 'Successfully deleted DAT course lesson!',
                icon : 'success',
                timer: 3000
                
                }
            ).then((result) => {
                dispatch(fetchDatCourses({}))
                dispatch(deleteDatCourseLessonsReset());
                
            })
          
        }
      
        else if(typeof props.deleteDatCourseLessons.error !== "undefined"){
       
            Swal.fire({
                title:'Failed!',
                text:'Failed to delete DAT course lesson!',
                icon :'error',
                timer: 3000
            
            }
          ).then((result) => {
            dispatch(fetchDatCourses({}))
            dispatch(deleteDatCourseLessonsReset());
            
          })    
                
        }
      
    }, [props.deleteDatCourseLessons])

    useEffect(() => {
    
  
        if(props.deleteDatCourseCategory.datcourse.length && typeof props.deleteDatCourseCategory.error === "undefined"){
          
            Swal.fire({
                title: 'Success!',
                text: 'Successfully deleted DAT course category!',
                icon : 'success',
                timer: 3000
              
            }
          ).then((result) => {
            dispatch(fetchDatCourses({}))
            dispatch(deleteDatCourseCategoryReset());
            
          })
          
        }
      
        else if(typeof props.deleteDatCourseCategory.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to delete DAT course category!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(fetchDatCourses({}))
            dispatch(deleteDatCourseCategoryReset());
            
          })    
                
        }
      
    }, [props.deleteDatCourseCategory])

    const handleCourseReset = (data) =>{
       setItems(data)
        // (props.fetchDatCourses.datcourses.find(course => course.datCourseId == props.courseData.datCourseId).category)
    }

    return (
        <>
            
            
                <Row className='mb-3'>

                    <Col md="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className='float-left'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        DAT Course
                                    </h6>
                                    <h2 className="mb-0">{props.courseData.courseTitle}</h2>
                                </div>
                                <div className='float-right'>
                                    <Button color="primary" onClick={toggleAddDatCourseCategoryModal}>Create Category</Button>
                                </div>
                            </CardHeader>
                            {/* <CardBody>
                                
                                
                            </CardBody> */}
                        </Card>
                        
                    </Col> 

                    <Col md="12">
                        
                    </Col>

                </Row>
                <DraggableSortList/>
                
           
            
            <AddDatCourseCategoryModal modalAddDatCourseCategoryModal={modalAddDatCourseCategoryModal} toggleAddDatCourseCategoryModal={toggleAddDatCourseCategoryModal} editCourseCategory={editCourseCategory} courseData={courseProps} categoryToUpdate={categoryToUpdate} items={items}/> 
            <AddDatCourseCategoryLessonsModal modalAddDatCourseCategoryLessonsModal={modalAddDatCourseCategoryLessonsModal} toggleAddDatCourseCategoryLessonsModal={toggleAddDatCourseCategoryLessonsModal} editCourseCategoryLessons={editCourseCategoryLessons} items={items} selectedCategory={selectedCategory} courseData={courseProps} lessonToUpdate={lessonToUpdate} categoryId={categoryId} handleCourseResetCallBack={handleCourseReset}/>              
        </>
      );
};

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createDatCourseCategory : state.createDatCourseCategory,
        fetchDatCourses : state.fetchDatCourses,
        deleteDatCourseLessons : state.deleteDatCourseLessons,
        deleteDatCourseCategory : state.deleteDatCourseCategory
        
    }
      
}

export default connect(mapStateToProps)(ManageDatCoursesLessons);