import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter,
    Badge
  } from "reactstrap";
import Swal from 'sweetalert2'
import {createTpcCourse, createTpcCourseReset, updateTpcCourse, updateTpcCourseReset} from 'redux/ManageTpcCourses/ManageTpcCoursesActions'
import { connect } from 'react-redux';
import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';

const AddTpcCourseModal = (props) => {

    let modalAddTpcCourse = props.modalAddTpcCourseModal;
    let toggleAddTpcCourse = props.toggleAddTpcCourseModal;

    const dispatch = useDispatch()

    const [courseDetails, setCourseDetails] = useState({ 
        course : {
            title : "",
            price : 0,
            description : "",
            thumbnail : "",
            members : "",
            visibility : ""
        },
        instructor : {
            name : "",
            bio : "",
            headshot : ""
        }
    });

    const visibilityOptions = [{value: "", label : "Select Visibility"},{value: "draft", label : "Draft"},{value: "published", label : "Published"}];
    const membersOptions = [{value: "", label : "Select Members"},{value: "free", label : "Free Members"},{value: "pro", label : "Pro Members"},{value: "ultimate", label : "Ultimate Advising Students"}];

    const [selectedCourseThumbnail, setSelectedCourseThumbnail] = useState(null);
    const [selectedInstructorHeadshot, setSelectedInstructorHeadshot] = useState(null);

    const handleImageChange = (event, imageFrom) => {
        const courseDetail = courseDetails;

        let imageDetails = ""
        if(imageFrom == "course"){
            courseDetail.course.thumbnail = event.target.files[0];
            imageDetails = courseDetail.course.thumbnail

            
        }
        else{
            courseDetail.instructor.headshot = event.target.files[0];
            imageDetails = courseDetail.instructor.headshot
        }
        

        
        
    
        // Check the file size
        const fileSizeInMB = imageDetails / (1024 * 1024);
        const maxSizeInMB = 5;
        if (fileSizeInMB > maxSizeInMB) {
            Swal.fire({
                title: 'Failed!',
                text: 'File size exceeds the maximum limit of 5MB.',
                icon: 'error',
                timer: 3000
            });
            return;
        }
        
        // Check if the file type is an image (JPEG or PNG)
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!allowedTypes.includes(imageDetails.type)) {
            Swal.fire({
                title: 'Failed!',
                text: 'Only image files (JPEG, PNG, JPG) are allowed.',
                icon: 'error',
                timer: 3000
            });
            return;
        }

        if (imageDetails) {
            const reader = new FileReader();

            reader.onload = function (e) {

                if(imageFrom == "course"){
                    setSelectedCourseThumbnail(e.target.result);
                }
                else{
                    setSelectedInstructorHeadshot(e.target.result);
                }
                
            };

            reader.readAsDataURL(imageDetails);
        } else {
   

            if(imageFrom == "course"){
                setSelectedCourseThumbnail(null);
            }
            else{
                setSelectedInstructorHeadshot(null);
            }
        }
        
        
        setCourseDetails(courseDetail);
    };

    const handleCourseTitleChange = (event) => {
        const courseDetail = courseDetails;
        courseDetail.course.title = event.target.value;
        setCourseDetails(courseDetail);
    };
    const handleCoursePriceChange = (event) => {
        const courseDetail = courseDetails;
        courseDetail.course.price = event.target.value;
        setCourseDetails(courseDetail);
    };
    const handleCourseDescriptionChange = (event) => {
        const courseDetail = courseDetails;
        courseDetail.course.description = event.target.value;
        setCourseDetails(courseDetail);
    };
    const handleInstructorNameChange = (event) => {
        const courseDetail = courseDetails;
        courseDetail.instructor.name = event.target.value;
        setCourseDetails(courseDetail);
    };
    const handleInstructorBioChange = (event) => {
        const courseDetail = courseDetails;
        courseDetail.instructor.bio = event.target.value;
        setCourseDetails(courseDetail);
    };

    const handleVisibilityChange = (event) => {
        const courseDetail = courseDetails;
        courseDetail.course.visibility = event.value;
        setCourseDetails(courseDetail);
    };

    const handleMembersChange = (event) => {
        const courseDetail = courseDetails;
        courseDetail.course.members = event.value;
        setCourseDetails(courseDetail);
    };
    

    


    const onClickSaveCourse = (e) => {
        e.preventDefault()

        Swal.showLoading()

        if(courseDetails.course.title == "" || courseDetails.course.visibility == "" || courseDetails.course.members == "" || courseDetails.instructor.name == "")
        {
            Swal.fire({
                title: 'Failed!',
                text: 'Please fill all the required field!',
                icon : 'error',
                timer: 3000
                
              }
            ).then((result) => {
             
               
            })
        }
        else{
            const formData = new FormData();
            formData.append('courseTitle', courseDetails.course.title);
            formData.append('coursePrice', courseDetails.course.price);
            formData.append('courseDescription', courseDetails.course.description);
            formData.append('courseThumbnail', courseDetails.course.thumbnail);
            formData.append('instructorBio', courseDetails.instructor.bio);
            formData.append('instructorName', courseDetails.instructor.name);
            formData.append('instructorHeadshot', courseDetails.instructor.headshot);
            formData.append('handle', props.userDetails.handle);
            formData.append('members', courseDetails.course.members);
            formData.append('visibility', courseDetails.course.visibility);
            dispatch(createTpcCourse(formData))
        }
        

        


    }


    const onClickUpdateCourse = (e) => {
        e.preventDefault()

        
        Swal.showLoading()
        
        if(courseDetails.course.title == "" || courseDetails.course.visibility == "" || courseDetails.course.members == "" || courseDetails.instructor.name == "")
        {
            Swal.fire({
                title: 'Failed!',
                text: 'Please fill all the required field!',
                icon : 'error',
                timer: 3000
                
              }
            ).then((result) => {
             
               
            })
        }
        else{

            const formData = new FormData();
            formData.append('courseTitle', courseDetails.course.title);
            formData.append('coursePrice', courseDetails.course.price);
            formData.append('courseDescription', courseDetails.course.description);
            formData.append('courseThumbnail', courseDetails.course.thumbnail);
            formData.append('instructorBio', courseDetails.instructor.bio);
            formData.append('instructorName', courseDetails.instructor.name);
            formData.append('instructorHeadshot', courseDetails.instructor.headshot);
            formData.append('handle', props.userDetails.handle);
            formData.append('tpcCourseId', courseDetails.tpcCourseId);
            formData.append('members', courseDetails.course.members);
            formData.append('visibility', courseDetails.course.visibility);
            dispatch(updateTpcCourse(formData))
        }


    }


    useEffect(() => {
    
  
        if(props.createTpcCourse.tpccourses.length && typeof props.createTpcCourse.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully added TPC course!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(createTpcCourseReset());
            toggleAddTpcCourse()
          })
          
        }
      
        else if(typeof props.createTpcCourse.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to add TPC course!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(createTpcCourseReset());
            toggleAddTpcCourse()
          })    
                
        }
      
    }, [props.createTpcCourse])

    useEffect(() => {
    
  
        if(props.updateTpcCourse.tpccourses.length && typeof props.updateTpcCourse.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully updated TPC course!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateTpcCourseReset());
            toggleAddTpcCourse()
          })
          
        }
      
        else if(typeof props.updateTpcCourse.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to update TPC course!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateTpcCourseReset());
            toggleAddTpcCourse()
          })    
                
        }
      
    }, [props.updateTpcCourse])

    useEffect(() => {
    
        // console.log(props.coursesData.tpccourses[props.editCourse].courseTitle)
        
        if(props.editCourse !== false){
            console.log(props.coursesData.tpccourses[props.editCourse])
            setCourseDetails(prevState => {
                return {
                    ...prevState,
                    course : {
                        ...prevState.course,
                        title : props.coursesData.tpccourses[props.editCourse].courseTitle,
                        price : props.coursesData.tpccourses[props.editCourse].coursePrice,
                        description : props.coursesData.tpccourses[props.editCourse].courseDescription,
                        image : props.coursesData.tpccourses[props.editCourse].courseThumbnail,
                        visibility : props.coursesData.tpccourses[props.editCourse].visibility,
                        members : props.coursesData.tpccourses[props.editCourse].members
                    },
                    instructor : {
                        ...prevState.instructor,
                        bio : props.coursesData.tpccourses[props.editCourse].instructorBio,
                        name : props.coursesData.tpccourses[props.editCourse].instructorName,
                        image : props.coursesData.tpccourses[props.editCourse].instructorHeadshot
                    },
                    tpcCourseId : props.coursesData.tpccourses[props.editCourse].tpcCourseId

                }
            })
            
            setSelectedCourseThumbnail(null);
            
        }

        else{
            setCourseDetails({ 
                course : {
                    title : "",
                    price : 0,
                    description : "",
                    thumbnail : "",
                    members : "",
                    visibility : ""
                },
                instructor : {
                    name : "",
                    bio : "",
                    headshot : ""
                }
            })

            setSelectedCourseThumbnail(null);

        }
        


    }, [props.editCourse])
    
    return (
        <div>
            <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddTpcCourse}  toggle={toggleAddTpcCourse}>
                    
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">{props.editCourse ? "Edit" : "Add"} TPC Course</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                            <h6 className="heading-small text-muted mb-4">
                                Course Information
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Visibility <span className='text-danger'>*</span>
                                            </label>
                                            
                                            <Select 
                                                placeholder='Select Visibility'
                                                options={visibilityOptions} 
                                                styles={customDropdown }  
                                                onChange={(e) => handleVisibilityChange(e)}
                                                menuPortalTarget={document.body}
                                                // value={visibilityOptions.find((option) => option.value === watch("inputTerm"))}
                                                defaultValue={props.editCourse !== false ? visibilityOptions.find((option) => option.value === courseDetails.course.visibility) : ""}
                                            />
                                            
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Members <span className='text-danger'>*</span>
                                            </label>
                                            <Select 
                                                placeholder='Select Members'
                                                options={membersOptions} 
                                                styles={customDropdown }  
                                                onChange={(e) => handleMembersChange(e)}
                                                menuPortalTarget={document.body}
                                                // value={visibilityOptions.find((option) => option.value === watch("inputTerm"))}
                                                defaultValue={props.editCourse !== false ? membersOptions.find((option) => option.value === courseDetails.course.members) : ""}
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>

                                            <label
                                                className="form-control-label"
                                             
                                            >
                                                Title <span className='text-danger'>*</span>
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                         
                                                defaultValue={courseDetails.course.title}
                                                onChange={e => handleCourseTitleChange(e)}
                                                type="text"
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>

                                            <label
                                                className="form-control-label"
                                             
                                            >
                                                Price <span className='text-danger'>*</span>
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                min={0}
                                                defaultValue={courseDetails.course.price}
                                                onChange={e => handleCoursePriceChange(e)}
                                                type="number"
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>

                                            <label
                                                className="form-control-label"
                                           
                                            >
                                                Description
                                            </label>
                                            
                                            <textarea
                                                rows="5"
                                                className="form-control "
                                                defaultValue={courseDetails.course.description}
                                                placeholder='Enter something...'
                                                onChange={e => handleCourseDescriptionChange(e)}
                                            />

                                        </FormGroup>
                                    </Col>

                                    <Col lg="12">
                                        <FormGroup>

                                            <label
                                                className="form-control-label"
                                             
                                            >
                                                Course Thumbnail
                                            </label>
                                            { selectedCourseThumbnail != null && selectedCourseThumbnail != "" ? 
                                                <>
                                                    <div className='text-center'>
                                                        <img
                                                            className='shadow img-thumbnail img-fluid'
                                                            src={selectedCourseThumbnail}
                                                            alt="Selected Image"
                                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    </> 
                                                : props.editCourse !== false && courseDetails.course.image != ""? 
                                                <>
                                                    <div className='text-center'>
                                                        <img
                                                            className='shadow img-thumbnail'
                                                            src={"https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/"+courseDetails.course.image}
                                                            alt="Selected Image"
                                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    </> 
                                                : ""
                                            }

                                            {/* {props.editCourse !== false ? 
                                                <>
                                                    <div className='text-center'>
                                                        <img
                                                            className='shadow img-thumbnail'
                                                            src={"https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/"+courseDetails.course.image}
                                                            alt="Selected Image"
                                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    </> 
                                                : ""
                                            } */}

                                            <div className="custom-file text-center mb-4">
                            
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    accept="image/*"
                                                    onChange={(event) => handleImageChange(event, "course")}
                                                />
                                                {/* <label className="custom-file-label" >{item.video ? <>Selected video file: <span className='text-primary'>{item.video.name}</span></> : "Select video file (max 10mb)"}</label> */}
                                                <label className="custom-file-label" >Select image file (max 5mb)</label>
                                            </div>

                                        </FormGroup>
                                    </Col>
                                    

                                    
                                </Row>
                            </div>

                            <hr className="my-4" />    
                            <h6 className="heading-small text-muted mb-4">
                                Instructor Information
                            </h6>

                            <div className="pl-lg-4">
                                <Row>

                                    

                                    <Col lg="12">
                                        <FormGroup>

                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Name <span className='text-danger'>*</span>
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                
                                                defaultValue={courseDetails.instructor.name}
                                                onChange={e => handleInstructorNameChange(e)}
                                                type="text"
                                            />

                                        </FormGroup>
                                    </Col>

                                    <Col lg="12">
                                        <FormGroup>

                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Bio 
                                            </label>
                                            
                                            <Input
                                                className="form-control-alternative"
                                                placeholder='Write something about the instructor...'
                                                defaultValue={courseDetails.instructor.bio}
                                                onChange={e => handleInstructorBioChange(e)}
                                                rows="4"
                                                type="textarea"
                                            />

                                        </FormGroup>
                                    </Col>
                                    
                                    <Col lg="12">
                                        <FormGroup>

                                            <label
                                                className="form-control-label"
                                               
                                            >
                                                Headshot
                                            </label>
                                            

                                            { selectedInstructorHeadshot != null && selectedInstructorHeadshot != "" ? 
                                                <>
                                                    <div className='text-center'>
                                                        <img
                                                            className='shadow img-thumbnail'
                                                            src={selectedInstructorHeadshot}
                                                            alt="Selected Image"
                                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    </> 
                                                : props.editCourse !== false && courseDetails.instructor.image != ""? 
                                                <>
                                                    <div className='text-center'>
                                                        <img
                                                            className='shadow img-thumbnail'
                                                            src={"https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/"+courseDetails.instructor.image}
                                                            alt="Selected Image"
                                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    </> 
                                                : ""
                                            }

                                            <div className="custom-file text-center mb-4">
                            
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    accept="image/*"
                                                    onChange={(event) => handleImageChange(event, "instructor")}
                                                />
                                                {/* <label className="custom-file-label" >{item.video ? <>Selected video file: <span className='text-primary'>{item.video.name}</span></> : "Select video file (max 10mb)"}</label> */}
                                                <label className="custom-file-label" >Select image file (max 5mb)</label>
                                            </div>

                                        </FormGroup>
                                    </Col>
                                    
                                </Row>
                            </div>
                            
                            
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"  onClick={toggleAddTpcCourse}>
                        Cancel
                    </Button>

                    {props.editCourse === false ? 
                        <Button color="primary" onClick={onClickSaveCourse}>
                            Save
                        </Button>
                        :
                        <Button color="primary" onClick={onClickUpdateCourse}>
                            Update
                        </Button>
                    }
                </ModalFooter>
                
            </Modal>
        </div>
    )
}
const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createTpcCourse : state.createTpcCourse,
        updateTpcCourse : state.updateTpcCourse,
        
    }
      
}

export default connect(mapStateToProps)(AddTpcCourseModal)
