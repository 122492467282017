export const CREATE_DAT_COURSE_REQUEST = 'CREATE_DAT_COURSE_REQUEST';
export const CREATE_DAT_COURSE_SUCCESS = 'CREATE_DAT_COURSE_SUCCESS';
export const CREATE_DAT_COURSE_FAILURE = 'CREATE_DAT_COURSE_FAILURE';
export const CREATE_DAT_COURSE_RESET = 'CREATE_DAT_COURSE_RESET';

export const FETCH_DAT_COURSES_REQUEST = 'FETCH_DAT_COURSES_REQUEST';
export const FETCH_DAT_COURSES_SUCCESS = 'FETCH_DAT_COURSES_SUCCESS';
export const FETCH_DAT_COURSES_FAILURE = 'FETCH_DAT_COURSES_FAILURE';


export const UPDATE_DAT_COURSE_REQUEST = 'UPDATE_DAT_COURSE_REQUEST';
export const UPDATE_DAT_COURSE_SUCCESS = 'UPDATE_DAT_COURSE_SUCCESS';
export const UPDATE_DAT_COURSE_FAILURE = 'UPDATE_DAT_COURSE_FAILURE';
export const UPDATE_DAT_COURSE_RESET = 'UPDATE_DAT_COURSE_RESET';

export const DELETE_DAT_COURSE_REQUEST = 'DELETE_DAT_COURSE_REQUEST';
export const DELETE_DAT_COURSE_SUCCESS = 'DELETE_DAT_COURSE_SUCCESS';
export const DELETE_DAT_COURSE_FAILURE = 'DELETE_DAT_COURSE_FAILURE';
export const DELETE_DAT_COURSE_RESET = 'DELETE_DAT_COURSE_RESET';

export const CREATE_DAT_COURSE_CATEGORY_REQUEST = 'CREATE_DAT_COURSE_CATEGORY_REQUEST';
export const CREATE_DAT_COURSE_CATEGORY_SUCCESS = 'CREATE_DAT_COURSE_CATEGORY_SUCCESS';
export const CREATE_DAT_COURSE_CATEGORY_FAILURE = 'CREATE_DAT_COURSE_CATEGORY_FAILURE';
export const CREATE_DAT_COURSE_CATEGORY_RESET = 'CREATE_DAT_COURSE_CATEGORY_RESET';

export const CREATE_DAT_COURSE_LESSONS_REQUEST = 'CREATE_DAT_COURSE_LESSONS_REQUEST';
export const CREATE_DAT_COURSE_LESSONS_SUCCESS = 'CREATE_DAT_COURSE_LESSONS_SUCCESS';
export const CREATE_DAT_COURSE_LESSONS_FAILURE = 'CREATE_DAT_COURSE_LESSONS_FAILURE';
export const CREATE_DAT_COURSE_LESSONS_RESET = 'CREATE_DAT_COURSE_LESSONS_RESET';

export const UPDATE_DAT_COURSE_CATEGORY_ORDER_REQUEST = 'UPDATE_DAT_COURSE_CATEGORY_ORDER_REQUEST';
export const UPDATE_DAT_COURSE_CATEGORY_ORDER_SUCCESS = 'UPDATE_DAT_COURSE_CATEGORY_ORDER_SUCCESS';
export const UPDATE_DAT_COURSE_CATEGORY_ORDER_FAILURE = 'UPDATE_DAT_COURSE_CATEGORY_ORDER_FAILURE';
export const UPDATE_DAT_COURSE_CATEGORY_ORDER_RESET = 'UPDATE_DAT_COURSE_CATEGORY_ORDER_RESET';

export const UPDATE_DAT_COURSE_LESSONS_ORDER_REQUEST = 'UPDATE_DAT_COURSE_LESSONS_ORDER_REQUEST';
export const UPDATE_DAT_COURSE_LESSONS_ORDER_SUCCESS = 'UPDATE_DAT_COURSE_LESSONS_ORDER_SUCCESS';
export const UPDATE_DAT_COURSE_LESSONS_ORDER_FAILURE = 'UPDATE_DAT_COURSE_LESSONS_ORDER_FAILURE';
export const UPDATE_DAT_COURSE_LESSONS_ORDER_RESET = 'UPDATE_DAT_COURSE_LESSONS_ORDER_RESET';

export const UPDATE_DAT_COURSE_CATEGORY_REQUEST = 'UPDATE_DAT_COURSE_CATEGORY_REQUEST';
export const UPDATE_DAT_COURSE_CATEGORY_SUCCESS = 'UPDATE_DAT_COURSE_CATEGORY_SUCCESS';
export const UPDATE_DAT_COURSE_CATEGORY_FAILURE = 'UPDATE_DAT_COURSE_CATEGORY_FAILURE';
export const UPDATE_DAT_COURSE_CATEGORY_RESET = 'UPDATE_DAT_COURSE_CATEGORY_RESET';

export const UPDATE_DAT_COURSE_LESSONS_REQUEST = 'UPDATE_DAT_COURSE_LESSONS_REQUEST';
export const UPDATE_DAT_COURSE_LESSONS_SUCCESS = 'UPDATE_DAT_COURSE_LESSONS_SUCCESS';
export const UPDATE_DAT_COURSE_LESSONS_FAILURE = 'UPDATE_DAT_COURSE_LESSONS_FAILURE';
export const UPDATE_DAT_COURSE_LESSONS_RESET = 'UPDATE_DAT_COURSE_LESSONS_RESET';


export const DELETE_DAT_COURSE_CATEGORY_REQUEST = 'DELETE_DAT_COURSE_CATEGORY_REQUEST';
export const DELETE_DAT_COURSE_CATEGORY_SUCCESS = 'DELETE_DAT_COURSE_CATEGORY_SUCCESS';
export const DELETE_DAT_COURSE_CATEGORY_FAILURE = 'DELETE_DAT_COURSE_CATEGORY_FAILURE';
export const DELETE_DAT_COURSE_CATEGORY_RESET = 'DELETE_DAT_COURSE_CATEGORY_RESET';

export const DELETE_DAT_COURSE_LESSONS_REQUEST = 'DELETE_DAT_COURSE_LESSONS_REQUEST';
export const DELETE_DAT_COURSE_LESSONS_SUCCESS = 'DELETE_DAT_COURSE_LESSONS_SUCCESS';
export const DELETE_DAT_COURSE_LESSONS_FAILURE = 'DELETE_DAT_COURSE_LESSONS_FAILURE';
export const DELETE_DAT_COURSE_LESSONS_RESET = 'DELETE_DAT_COURSE_LESSONS_RESET';

export const UPDATE_DAT_MARKED_COMPLETE_LESSON_REQUEST = 'UPDATE_DAT_MARKED_COMPLETE_LESSON_REQUEST';
export const UPDATE_DAT_MARKED_COMPLETE_LESSON_SUCCESS = 'UPDATE_DAT_MARKED_COMPLETE_LESSON_SUCCESS';
export const UPDATE_DAT_MARKED_COMPLETE_LESSON_FAILURE = 'UPDATE_DAT_MARKED_COMPLETE_LESSON_FAILURE';
export const UPDATE_DAT_MARKED_COMPLETE_LESSON_RESET = 'UPDATE_DAT_MARKED_COMPLETE_LESSON_RESET';

export const FETCH_DAT_MARKED_COMPLETE_LESSON_REQUEST = 'FETCH_DAT_MARKED_COMPLETE_LESSON_REQUEST';
export const FETCH_DAT_MARKED_COMPLETE_LESSON_SUCCESS = 'FETCH_DAT_MARKED_COMPLETE_LESSON_SUCCESS';
export const FETCH_DAT_MARKED_COMPLETE_LESSON_FAILURE = 'FETCH_DAT_MARKED_COMPLETE_LESSON_FAILURE';
export const FETCH_DAT_MARKED_COMPLETE_LESSON_RESET = 'FETCH_DAT_MARKED_COMPLETE_LESSON_RESET';


export const CREATE_DAT_BUNDLECOURSE_REQUEST = 'CREATE_DAT_BUNDLECOURSE_REQUEST';
export const CREATE_DAT_BUNDLECOURSE_SUCCESS = 'CREATE_DAT_BUNDLECOURSE_SUCCESS';
export const CREATE_DAT_BUNDLECOURSE_FAILURE = 'CREATE_DAT_BUNDLECOURSE_FAILURE';
export const CREATE_DAT_BUNDLECOURSE_RESET = 'CREATE_DAT_BUNDLECOURSE_RESET';

export const FETCH_DAT_BUNDLECOURSE_REQUEST = 'FETCH_DAT_BUNDLECOURSE_REQUEST';
export const FETCH_DAT_BUNDLECOURSE_SUCCESS = 'FETCH_DAT_BUNDLECOURSE_SUCCESS';
export const FETCH_DAT_BUNDLECOURSE_FAILURE = 'FETCH_DAT_BUNDLECOURSE_FAILURE';
export const FETCH_DAT_BUNDLECOURSE_RESET = 'FETCH_DAT_BUNDLECOURSE_RESET';

